import React from "react";

export default function FormCustom({whenSaved, whenClosed, prefilled, hasEdit}) {
    const isPrefilledEu = (eu) => {
        return eu == 1;
    };
    const isPrefilledThirdCountry = (eu) => {
        return eu == 0;
    };

    return(
        <>
            <div>
                <button type="button" className="block bg-transparent text-ere-link-blue py-1 px-2 border-2 border-ere-link-blue float-right" onClick={() => whenClosed()}>
                    <p className="block uppercase tracking-wide font-bold text-sm">x</p>
                </button>
            </div>
            <div>
                {hasEdit ?
                    <p className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2">Bearbeitung von "{prefilled.company}"</p>
                :
                    <></>
                }

                <div id="custom-service-provider-form"  className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-password">Firmenname</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none"
                               name="company" type="text" defaultValue={prefilled ? prefilled.company : ''} required/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Straße</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none"
                               name="street" type="text" defaultValue={prefilled ? prefilled.street : ''} required/>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-last-name">Hausnummer</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none"
                               name="housenumber" type="text" defaultValue={prefilled ? prefilled.housenumber : ''} required/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Postleitzahl</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none"
                               name="zipcode" type="text" defaultValue={prefilled ? prefilled.zipcode : ''} required/>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-last-name">Ort</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none"
                               name="city" type="text" defaultValue={prefilled ? prefilled.city : ''} required/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Land</label>
                        <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none"
                               name="country" type="text" defaultValue={prefilled ? prefilled.country : ''} required/>
                    </div>
                    <div className="w-full md:w-6/12 px-3 ">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-last-name">Sitz des Unternehmens</label>
                        <label className="flex text-ere-grey-alternative items-center">
                            <input style={{accentColor: '#056688'}} className="w-4 h-4 bg-ere-checkbox-blue border-ere-checkbox-blue focus:ring-ere-link-blue" name="eu" type="radio" value="1"  defaultChecked={prefilled ? isPrefilledEu(prefilled.eu) : false} required/>
                            <span className="ml-2 text-sm">Europäische Union</span>
                        </label>
                        <label className="flex text-ere-grey-alternative items-center">
                            <input style={{accentColor: '#056688'}} className="w-4 h-4 bg-ere-checkbox-blue border-ere-checkbox-blue focus:ring-ere-link-blue" name="eu" type="radio" value="0" defaultChecked={prefilled ? isPrefilledThirdCountry(prefilled.eu) : false}  required />
                            <span className="ml-2 text-sm">Drittstaat</span>
                        </label>
                    </div>
                </div>
                { !hasEdit ?
                    <button type="submit" className="place-self-center bg-transparent text-ere-link-blue py-2 px-4 border-2 border-ere-link-blue mr-3" onClick={() => whenSaved("submit_custom_service_provider")}>
                        <p className="block uppercase tracking-wide font-bold text-sm">Speichern</p>
                    </button>
                :
                    <button type="submit" className="place-self-center bg-transparent text-ere-link-blue py-2 px-4 border-2 border-ere-link-blue" onClick={() => whenSaved("edit_custom_service_provider", prefilled.id)}>
                        <p className="block uppercase tracking-wide font-bold text-sm">Speichern</p>
                    </button>
                }
            </div>
        </>
    );
}