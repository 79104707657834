import React from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import GradientSVG from "./gradientSVG";
import '../../../index.css';

export default function Indicator({done, small}) {
    const idCSS = "hello";
    return(
        <div className={`${small 
            ? "progress-container-small flex flex-row h-[22px] justify-self-start text-sm " 
            : "progress-container min-w-[104px] flex flex-col items-center textbase w-[90px] h-[90px] self-center xl:mt-[20px]"}`}>

            { done === 100
                ? <span className={`${small   ? "w-[22px] h-[22px] icon-done-status text-ere-green text-unset inline-block text-[22px]"
                    : "w-[50px] h-[50px] icon-done-status text-ere-green text-unset inline-block text-[50px]"}`} />
                :   <div className={
                    `${small   ? "w-[22px] h-[22px] inline"
                                : "w-[50px] h-[50px]"}`} >
                    <GradientSVG/>
                    <CircularProgressbar className="CircularProgressbar-trail"
                                         strokeWidth={25}
                                         value={done}
                                         styles={{
                                             path: { stroke: `url(#${idCSS})`, height: "100%" },
                                             trail: {
                                                 stroke: "#E0E0E0"
                                             }
                                         }}
                    />
                    </div>
            }
            <p className={`${small  ? "inline-block w-[110px] text-start text-sm pl-1"
                                    : "inline-block w-[110px] text-center text-base mt-2 leading-[16px]"}`}>{done}% erledigt</p>
        </div>
    );
}