import React from 'react';
import * as ReactDOM from "react-dom/client";
import {createHashRouter, RouterProvider,} from "react-router-dom";
import Main from "./layout/Main";
import Error from "./error";
import ShowParents from "./views/category/ShowParents";
import Dashboard from "./views/Dashboard";
import ShowChildren from "./views/category/ShowChildren";
import QuestionCatalog from "./views/category/question/QuestionCatalog";
import ShowList from "./views/task/ShowList";
import ShowDetail from "./views/task/ShowDetail";
import ShowProjectList from "./views/project/ShowList";
import ShowProjectDetail from "./views/project/ShowDetail";
import ShowContractList from "./views/contract/ShowList";
import './index.css';
import {setCookie, getCookieContentByName} from "./utils/helpers";

const router = createHashRouter([
    {
        path: "/",
        element: <Main/>,
        errorElement: <Error/>,
        children: [
            {
                path: "/",
                element: <Dashboard/>,
            },
            {
                path: "/tasks",
                element: <ShowList/>,
            },
            {
                path: "/tasks/:task_id",
                element: <ShowDetail/>,
            },
            {
                path: "/contracts",
                element: <ShowContractList/>,
            },
            {
                path: "/projects",
                element: <ShowProjectList/>,
            },
            {
                path: "/projects/:project_id",
                element: <ShowProjectDetail/>,
            },
            {
                path: "/categories",
                element: <ShowParents/>,
            },
            {
                path: "/categories/:category_id/:category_name",
                element: <ShowChildren/>,
            },
            {
                path: "/categories/:category_id/:category_name/questions/:question_id/:question_name",
                element: <QuestionCatalog/>,
            },

        ]
    },
]);

if (process.env.NODE_ENV === 'development') {

    // TODO: don't do that
    document.ert_shadow = document;

    // set default cookie in development
    let eRecht24Cookie = getCookieContentByName(process.env.REACT_APP_COOKIE_NAME);
    if (eRecht24Cookie == '') {
        setCookie(process.env.REACT_APP_COOKIE_NAME, process.env.REACT_APP_DEFAULT_JWT, 30);
    }
    console.log("cookie", eRecht24Cookie);



    ReactDOM.createRoot(document.getElementById("root")).render(
        <React.StrictMode>
            <RouterProvider router={router}/>
        </React.StrictMode>
    );
}

if (process.env.NODE_ENV === 'production') {
    document.addEventListener('erecht24event', (event) => {

        // TODO: don't do that
        document.ert_shadow = event.detail.shadow;

        ReactDOM.createRoot(event.detail.div).render(
            <React.StrictMode>
                <RouterProvider router={router}/>
            </React.StrictMode>
        );
    })
}