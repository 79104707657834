import React, {useEffect, useState} from "react";

export default function ServiceProvider({questionAnswers, whenChecked, prefilled}) {
    const [providers, setProviders] = useState([]);

    useEffect(()=> {
        setProviders(questionAnswers);
        document.ert_shadow.querySelectorAll('[name="service_provider"]').forEach((provider) => {
            if(prefilled && provider.value == prefilled.split(",").find((id) => id == provider.value)) {
                provider.checked = true;
            }
        })
    },[questionAnswers, prefilled])

    const listProviders = providers.filter(function(provider) {
        if(provider.id !== 77777 && provider.id !== 99999 && provider.top === 1) {
            return provider;
        }
    }).map((provider) => (
        <label key={provider.id} className={`bg-white shadow-md rounded-sm border border-sky-600 border-opacity-40 transition-all active:scale-95 peer-checked:ring-ere-checkbox-blue relative cursor-pointer flex justify-center overflow-visible w-full max-w-[195px] min-w-[180px] max-h-[107px] h-auto ${prefilled && provider.id == prefilled.split(",").find((id) => id == provider.id) ? "ring-ere-checkbox-blue" : "ring-ere-link-blue"}`}>
            <input type="checkbox" className="peer sr-only" value={provider.id} name="service_provider"/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px]">
                <div className="bg-ere-checkbox-blue w-[24px] text-center rounded-full">
                    <span className="icon-check"/>
                </div>
            </span>
            {provider.icon ?
                <img className="p-4 w-full object-contain" src={provider.icon} loading="lazy" alt={""}/>
            :
                <header className="flex flex-col justify-center items-center py-10">
                    <p className="w-full">{provider.name}</p>
                </header>
            }
        </label>
    ))

    const listNone = providers.filter(function(provider) {
        if(provider.id === 77777) {
            return provider;
        }
    }).map((provider) => (
        <label key={provider.id} className="bg-white shadow-md transition-all active:scale-95 peer-checked:ring-ere-checkbox-blue relative cursor-pointer rounded-sm border border-sky-600 border-opacity-40 flex justify-center overflow-visible w-full  max-w-[195px] min-w-[180px] max-h-[107px]
        h-auto p-3">
            <input type="checkbox" className="peer sr-only" value={provider.id} name="service_provider"/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px]">
                <div className="bg-ere-checkbox-blue w-[24px] text-center rounded-full">
                    <span className="icon-check "/>
                </div>
            </span>
            <header className="flex flex-col justify-center items-center ">
                <div className="text-[40px]">
                    <span className="icon-nein"></span>
                </div>
                <p className="text-base">Keine</p>
            </header>
        </label>
    ))

    const listOther = providers.filter(function(provider) {
        if(provider.id === 99999) {
            return provider;
        }
    }).map((provider) => (
        <label key={provider.id}  className="bg-white shadow-md transition-all active:scale-95 peer-checked:ring-ere-checkbox-blue cursor-pointer relative rounded-sm border border-sky-600 border-opacity-40 flex justify-center overflow-visible min-w-[180px] w-full max-w-[195px] max-h-[107px] h-auto p-3">
            <input type="checkbox" className="peer sr-only" value={provider.id} name="service_provider" onChange={(e) => whenChecked(e)}/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px]">
                <div className="bg-ere-checkbox-blue w-[24px] text-center rounded-full">
                    <span className="icon-check"/>
                </div>
            </span>
            <div >
                <header className="flex flex-col justify-center items-center">
                    <div className="text-[40px]">
                        <span className="icon-sonstiges"></span>
                    </div>
                    <p className="text-base">Sonstige</p>
                </header>
            </div>
        </label>
    ))

    return(
        <div className="md:flex flex-wrap justify-center grid grid-cols-1 s:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-3 mb-6 md:w-fit self-center w-max">
            {listProviders}
            {listOther}
            {listNone}
        </div>
    );
}