import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchProjects} from "../../../utils/api/projects";
import {bearerToken} from "../../../utils/helpers";
import {loggedInUser} from "../../../utils/helpers";

export default function Overview() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        function waitForToken() {
            if(typeof bearerToken !== "undefined") {

                if(loggedInUser.protection_officer == 1) {
                    fetchProjects(setProjects);
                }

            } else {
                setTimeout(waitForToken, 250);
            }
        }
        waitForToken()
    },[])

    const listProjects = projects?.map(project => (
        <li key={project.id} className="flow-root m-3">
            <div className="float-left">
                <Link to={`/projects/${project.id}`} state={{current_project: project}} className="float-right text-ere-link-blue">{project.company}</Link>
            </div>
            <Link to={`/projects/${project.id}`} state={{current_project: project}} className="float-right text-ere-link-blue">{loggedInUser.selected_project === project.id ? 'Aktuell ausgewählt' : 'Zum Projekt'}<span className="icon-arrow-right ml-2"/></Link>
        </li>
    ))


    if ((loggedInUser !== undefined) && loggedInUser.protection_officer === 1) {
        return(
         <div className="m-0 p-3 md:m-5 flex flex-col risk-card w-[100%] max-w-[963px] h-fit bg-white rounded border light-blue-border md:p-[24px] md:flex-row xl:justify-between mb-[48px]">
                <div className="lg:w-[100%] lg:flex-row">
                    <div className="flow-root items-center mb-4">
                        <h1 className="float-left text-xl text-ere-content-blue">Projekte</h1>
                        <Link to={"/projects"} className="float-right text-ere-link-blue text-sm uppercase underline">Alle Projekte ansehen</Link>
                    </div>
                    <div className="text-sm text-center mb-4">
                        <ul className="flex -mb-px font-bold">
                            <li>
                                <button type="button" className="inline-block py-2 px-4 text-ere-link-blue border-b-4 border-ere-link-blue rounded-t-lg active uppercase">Alle Projekte</button>
                            </li>
                            <li>
                                <button type="button" className="inline-block py-2 px-4 text-ere-dark-gray border-b-2 border-ere-dark-gray rounded-t-lg uppercase">Meine Projekte in Betreuung</button>
                            </li>
                        </ul>
                    </div>
                    <ul className="list-none text-ere-grey-alternative">
                        {listProjects.length !== 0 ? listProjects : <>Es gibt keine Projekte, die betreut werden müssen.</>}
                    </ul>
                </div>
            </div>
        );
    }



}