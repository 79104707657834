import React from "react";
import '../../index.css';
import Indicator from "../Content/Progress/Indicator";
import {globalQuestionProgress} from "../../utils/helpers";

export default function Report() {
    return (
        <div className="min-w-[250px] h-[281px] relative bg-white border border-sky-950 border-opacity-5 flex flex-col overflow-hidden p-[24px] text-center shadow-sm">
            <div className="text-xl font-bold leading-normal text-ere-content-blue ">Ihr Fortschritt</div>
            <div className="top-[56px] text-sm font-normal text-ere-grey-alternative">Lorem ipsum dolore sit amet</div>
            <Indicator done={Math.floor(globalQuestionProgress.progress_value)} small={false}/>
            <button className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover text-[14px] uppercase mt-auto font-black tracking-[0.233px]">
                bericht erstellen
            </button>
        </div>
    )

}