import {bearerToken} from "../helpers";

export async function fetchContracts(setState) {
    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/contracts`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setState(data);
        })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function fetchContractFile(contract) {
    fetch(contract.filelink, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
            Authorization: bearerToken
        },
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${contract.serviceprovider.name}_contract.pdf`.toLowerCase(),
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
}