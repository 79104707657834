import React from "react";
import { ReactComponent as ArrowsSvg } from "../assets/icons/arrows.svg";

export default function Header({collapsed, collapsedFunction, arrowsVisibleFunction}) {
    return (
        <div className="h-[74px] w-full absolute top-0 left-0 header">

            {
                collapsed ?
                    <div className="h-[74px] bg-ere-content-blue">
                        <div
                            className="text-2xl text-white font-semibold leading-normal h-[74px] w-[72px] ml-[23px] flex">

                            <span className="icon-collapse-sidebar w-[24px] h-[24px] cursor-pointer transition-all duration-800 ease-in z-2 text-center rotate-180 self-center mb-1" onClick={() => {
                                collapsedFunction();
                                setTimeout(3000);
                                arrowsVisibleFunction();
                            }}>
                                <ArrowsSvg className="w-[30px] h-[30px] scale-[1.2]"/>
{/*
                                <img className="w-[30px] h-[30px] scale-[1.2]"  src={process.env.REACT_APP_URL + arrows} alt="arrows"/>
*/}
                            </span>
                        </div>
                    </div>
                :
                    <div className="h-[74px] p-6 pt-[20px] justify-center bg-ere-content-blue">
                        <div className="text-2xl text-white font-semibold leading-normal">Firmenname</div>
                    </div>
            }
        </div>
    )
}