import React, {useState} from "react";

export default function Popup({popupAnswer}) {
    const [popupVisible,setPopupVisible] = useState(false);
    return(
        <>
                    <span className="absolute right-0 bottom-[5px] icon-info px-1 text-[22px] z-3 hover:scale-125 gray-info-icon"
                          onClick={(e)=> {

                              e.preventDefault();
                              setPopupVisible(true);


                          }}
                    />

            {popupVisible ? <div className="popup text-start flex flex-col justify-between absolute left-[-4%] top-[-15%] s:left-[97%] lg:left-[97%] s:top-[91%] min-w-[150px] min-h-[120px] md:min-w-[150px] md:min-h-[100px] h-fit bg-ere-body-color text-ere-grey-alternative shadow-m border break-words text-sm p-4 animate-appear duration-500 delay-100 cursor-default">
                    <p className="pt-[5px]">
                        {popupAnswer}
                    </p>
                    <button className="w-8 h-8 hover:bg-ere-light-gray/10  rounded-full md:text-[20px] text-center z-3 absolute top-0 right-0"
                            onClick={(e)=> {
                                e.currentTarget.parentElement.classList.add('animate-disappear');
                                e.preventDefault();
                                e.stopPropagation();
                                e.currentTarget.parentElement.classList.toggle('animate-appear');

                              /*  e.stopImmediatePropagation();*/

                                setPopupVisible(false);

                            }}
                    >x</button>
                </div>
                :
                null
            }
        </>
    );
}