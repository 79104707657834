import React, {useState} from "react";

export default function Upload() {
    const [file, setFile] = useState(null)
    const [comment, setComment] = useState("")

    return(
        <div className="mb-6">
            <label className="relative cursor-pointer">
                <input type="file" accept="application/pdf" name="upload" className="hidden" onChange={(e) => setFile(e.target.files[0])}/>
                <div className="overflow-hidden bg-white shadow-md ring ring-ere-link-blue transition-all active:scale-95 peer-checked:ring-ere-checkbox-blue">
                    <header className="px-10 py-8 text-center">
                        <div className="icon-plus-circle mb-6" style={{fontSize: 25}}></div>
                        {file ? file.name : <></>}
                        <p className="text-lg text-center font-bold tracking-wide text-gray-700">Wählen Sie ein oder mehrere Dokumente aus (.pdf)</p>
                    </header>
                </div>
            </label>
            <div className="flex flex-col text-start flex-nowrap lg:pb-[10%] pb-[40%] mt-3">
                <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-password">Kommentar</label>
                        <input type="text" name="comment" className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" onChange={(e) => setComment(e.target.value)} value={comment} required/>
                    </div>
                </div>
            </div>
        </div>
    );
}