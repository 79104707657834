import React, {useState} from "react";

export default function PopupQuestion({questionTitle,popupAnswer}) {

    const [popupQuestionVisible,setPopupQuestionVisible] = useState(false);

    return(<div className="flex justify-center relative items-center">
        <h2 className="text-base md:text-lg has-tooltip w-fit self-center flex content-center cursor-pointer "
            onClick={(e)=> {
                e.preventDefault();
                setPopupQuestionVisible(true);
            }}>
                {questionTitle}


        </h2>
         <span className="icon-info px-1 text-[22px] cursor-pointer z-3 hover:scale-125 gray-info-icon"
                 onClick={(e)=> {

                     e.preventDefault();
                     setPopupQuestionVisible(true);


                 }}
            />
            {
                popupQuestionVisible
                            ? <div className="popup text-start flex flex-col justify-between absolute top-[10px] right-[20px] lg:left-[72%] md:left-[87%] s:top-[20px] min-w-[150px] min-h-[120px] md:min-w-[150px] md:min-h-[100px] h-fit bg-ere-body-color text-ere-grey-alternative shadow-m border break-words text-sm p-4 z-10 ">
                                <p className="pt-[5px]">
                                    {popupAnswer}
                                </p>
                                <button className="w-8 h-8 hover:bg-ere-light-gray/10  rounded-full md:text-[20px] text-center z-3 absolute top-1 right-1"
                                        onClick={(e)=> {
                                            e.preventDefault();
                                            setPopupQuestionVisible(false);
                                        }}
                                >x</button>
                            </div>
                    :       <></>
            }

        </div>



    );
}