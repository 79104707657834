import {getCookieContentByName, setBearerToken, setLoggedInUser} from "../helpers";

export async function fetchToken() {

    let jwtToken = getCookieContentByName(process.env.REACT_APP_COOKIE_NAME);

    if (jwtToken === '') {
        throw new Error('eRecht24 Cookie is missing');

        // TODO: Show message in frontend -> not authenticated

    } else {
        await fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/login24`, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${jwtToken}`
            },
            method: "POST"
        })
            .then(response => response.json())
            .then((data) => {
                setBearerToken(data.success.token);
                setLoggedInUser(data.success.user);
            })
    }
}