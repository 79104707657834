import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {bearerToken, toSlug} from "../../../utils/helpers";
import {fetchCategoryQuestions} from "../../../utils/api/categories";
import Indicator from "../Progress/Indicator";

export default function Box({category, hasChildren}) {
    const [categoryQuestions, setCategoryQuestions] = useState([]);
    const lastChanged = new Date(category.answer_progress.last_changes_at)
    const navigate = useNavigate();

    useState(() => {
        function waitForToken() {
            if(categoryQuestions.length === 0) {
                if(typeof bearerToken !== "undefined") {
                    fetchCategoryQuestions(setCategoryQuestions, category.id)
                } else {
                    setTimeout(waitForToken, 250);
                }
            }
        }
        waitForToken()
    },[categoryQuestions])

    function whenClicked() {
        if(categoryQuestions.length !== 0) {
            let firstQuestion = Object.assign({}, categoryQuestions[0]);
            if(firstQuestion.show_only_as_dependency) {
                firstQuestion = Object.assign({}, categoryQuestions[1]);
            }
            navigate(`/categories/${category.id}/${toSlug(category.title)}/questions/${firstQuestion.id}/${toSlug(firstQuestion.title)}`, {
                state: {
                    current_category: category,
                    current_question: firstQuestion,
                    current_category_questions: categoryQuestions.filter((question) => question.show_only_as_dependency == 0),
                    current_index: 0
                }
            })
        } else if(hasChildren) {
            navigate(`/categories/${category.id}/${toSlug(category.title)}`, {
                state: {
                    current_category: category.id
                }
            });
        }
    }

    return (
        <button type="button" onClick={e => whenClicked(e, category)} className="w-auto category md:min-w-[305px] h-[140px] bg-white rounded-sm border border-sky-600 border-opacity-40 flex flex-col justify-between py-[12px] px-[16px]  hover:shadow-lg hover:scale-105 transition-all duration-500 hover:bg-ere-category-hover focus:shadow-lg focus:scale-105 focus:bg-ere-category-hover">
            <div className="flex flex-col items-start">
                <h3 className="text-[18px] text-ere-content-blue font-semibold text-left">{category.title}</h3>
                {category.answer_progress.last_changes_at ?
                    <span className="text-ere-grey-alternative text-[12px]">Zuletzt bearbeitet: {('0' + lastChanged.getDate()).slice(-2) + '.' + (('0' + (lastChanged.getMonth() + 1)).slice(-2)) + '.' + lastChanged.getFullYear() + ', ' + ('0' + lastChanged.getHours()).slice(-2) + ':' + ('0' + lastChanged.getMinutes()).slice(-2) + ' Uhr'}</span>
                :
                    <span className="text-ere-grey-alternative text-[12px]">Noch nicht bearbeitet</span>
                }

            </div>
            <div className="flex justify-between w-full">
                <Indicator done={Math.floor(category.answer_progress.progress_value)} small={true}/>
                <span className="icon-arrow-right text-base hover:scale-125"></span>
            </div>
        </button>
    );
}
