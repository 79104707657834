import React from "react";
import "../../../assets/css/progressbar.css"

export default function Bar({done}) {
    return (
        <div className="h-[6px] w-full absolute bottom-0 left-0 hidden lg:block ">
            { done === 100 ?
                <div style={{"width": `${done}%`}} className="h-[6px] relative progress-bar-100 ">
                    <div style={{"left": `${done}%`}} className=""></div>
{/*
                    <img src={banner} alt="banner" className="w-[84px] ml-[-42px] top-[-12px] absolute transition-all animate-pulse duration-500" style={{"left": `${done}%`}}/>
*/}
                    <span className="icon-process icon-process-done top-[6px] absolute transition-all animate-pulse duration-500 block text-[34px] left-[100%]" >
                         <span className="w-[44px]  text-[14px] text-white absolute left-[-14px] top-[12px]" >{done}%</span>
                    </span>

                </div>
                :
                <div style={{"width": `${done}%`}} className="h-[6px] progress-bar group relative">
                    <div style={{"left": `${done}%`}} className=""></div>
                    <span className="icon-process top-[6px] absolute transition-all animate-pulse duration-500 block text-[34px] left-[100%]" >
                           <span className="w-[44px] text-[14px] text-white absolute left-[-20px] top-[12px] font-['Lato']" >{done}%</span>

                    </span>

                </div>
            }
        </div>
    )
}



