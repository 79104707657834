import React, {useEffect, useState} from "react";
import {fetchTasks} from "../../../utils/api/tasks";
import {Link} from "react-router-dom";
import {bearerToken} from "../../../utils/helpers";

export default function Overview({isTaskOverview}) {
    const [tasks, setTasks] = useState([]);
    const [showUndone, setShowUndone] = useState(true);
    
    useEffect(() => {
        function waitForToken() {
            if(typeof bearerToken !== "undefined") {
                fetchTasks(setTasks)
            } else {
                setTimeout(waitForToken, 250);
            }
        }
        waitForToken()
    },[showUndone])

    function getUndoneTasks() {
        setShowUndone(true);
    }

    function getDoneTasks() {
        setShowUndone(false);
    }

    const listUndoneTasks = tasks?.filter(function(task) {
        if(task.state === "open") {
            return task;
        }
    }).map(task => (
        <li key={task.task.id} className="m-3 w-full sm:flex-row justify-between flex flex-col">
            <Link to={`/tasks/${task.id}`} state={{current_task: task}} className="float-left">{task.task.title}{task.task.risk.risk_value > 66 ? <span className="icon-attention-red ml-1"/> : <></>}</Link>
            <Link to={`/tasks/${task.id}`} state={{current_task: task}} className="text-ere-link-blue self-end">Zur Aufgabe<span className="icon-arrow-right ml-2 text-sm"/></Link>
        </li>
    ))

    const listDoneTasks = tasks?.filter(function(task) {
        if(task.state === "done") {
            return task;
        }
    }).map(task => (
        <li key={task.task.id} className="m-3 w-full sm:flex-row justify-between flex flex-col">
            <Link to={`/tasks/${task.id}`} state={{current_task: task}} className="float-left"><span className="icon-done-status mr-2"/>{task.task.title}</Link>
            <Link to={`/tasks/${task.id}`} state={{current_task: task}} className="text-ere-link-blue self-end">Zur Aufgabe<span className="icon-arrow-right ml-2 text-sm"/></Link>
        </li>
    ))

    return(
        <div className="m-0 p-3 md:m-5 flex flex-col risk-card  md:w-full max-w-[963px] h-fit bg-white rounded border light-blue-border md:p-[24px] md:flex-row xl:justify-between mb-[48px] w-full">
            <div className="w-[100%] lg:flex-row">
                <div className="flow-root items-center mb-4">
                    <h1 className="float-left text-xl text-ere-content-blue">Aufgaben</h1>
                    {!isTaskOverview ?
                        <Link to={"/tasks"} className="float-right text-ere-link-blue text-sm uppercase underline">Alle Aufgaben ansehen</Link>
                    :
                        <></>
                    }
                </div>
                <div className="text-sm text-center mb-4">
                    <ul className="flex -mb-px font-bold w-fit">
                        <li>
                            <button type="button" onClick={() => {getUndoneTasks()}} className={`w-[100px] sm:w-fit inline-block py-2 px-4 ${ showUndone ? "text-ere-link-blue border-b-4 border-ere-link-blue" : "text-ere-dark-gray border-b-2 border-ere-dark-gray"} rounded-t-lg uppercase`}>Unerledigt</button>
                        </li>
                        <li>
                            <button type="button" onClick={() => {getDoneTasks()}} className={`w-[100px] sm:w-fit inline-block py-2 px-4 ${ showUndone ? "text-ere-dark-gray border-b-2 border-ere-dark-gray" : "text-ere-link-blue border-b-4 border-ere-link-blue"} rounded-t-lg uppercase`}>Erledigt</button>
                        </li>
                    </ul>
                </div>
                { showUndone ?
                    <ul className="list-disc text-ere-grey-alternativebg-red-600 w-full text-sm font-bold">
                        {listUndoneTasks.length !== 0 ? listUndoneTasks : <>Es gibt keine Aufgaben zu erledigen.</>}
                    </ul>
                :
                    <ul className="list-disc text-ere-grey-alternativebg-red-600 w-full text-sm font-bold">
                        {listDoneTasks.length !== 0 ? listDoneTasks : <>Es gibt keine erledigten Aufgaben.</>}
                    </ul>
                }
            </div>
        </div>
    );
}