import {bearerToken} from "../helpers";

export async function fetchAnswer(setState, questionId) {

    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/project-choices/${questionId}`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setState(data);
        })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function postAnswer(formInformation) {
    await fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/project-choices/${formInformation.question_id}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        },
        body: JSON.stringify({
            question_type: formInformation.question_type,
            answer: formInformation.answer,
            additional_text: formInformation.additional_text,
            additional_data: formInformation.additional_data
        })
    })
    .catch((error) => {
        console.log(error.message);
    });
}