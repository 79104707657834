import React, {useEffect} from "react";

export default function Textfield({texttype, prefilled}) {
    let content;

    useEffect(() => {},[prefilled, texttype])

    if (texttype === "textfield") {
        content = <input type="text" name="textfield" className="border-2 border-ere-link-blue text-ere-content-blue text-sm block w-full p-2.5" placeholder="Bitte füllen Sie dieses Feld aus" defaultValue={prefilled ? prefilled : ""}/>
    } else {
        content = <textarea name="textfield" rows="4" className="border-2 border-ere-link-blue text-ere-content-blue text-sm block w-full p-2.5" placeholder="Bitte füllen Sie dieses Feld aus" defaultValue={prefilled ? prefilled : ""}/>
    }

    return(
        <div className="my-6 mb-6 ">
            {content}
        </div>
    );
}