import React from 'react';
import GaugeChart from "react-gauge-chart";

export default function Meter({riskValue}){
    return (
        <div className=" flex flex-col self-center justify-center">
            <GaugeChart className="flex justify-center items-center"
                        style={{ width: '104px'}}
                nrOfLevels={3}
                arcsLength={[0.33, 0.33, 0.33]}
                colors={["#56d7b0", "#f5db49", "#ff6f47"]}
                percent={riskValue/100}
                arcPadding={0.05}
                textColor="black"
                animDelay ={500}
                hideText={true}
                cornerRadius={1}
                arcWidth={0.3}

            />
            <p className="text-base self-center">Ihr Risiko</p>
        </div>
    );
}


