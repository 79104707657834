import {bearerToken} from "../helpers";

export async function fetchProjects(setState) {
    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/projects`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setState(data);
        })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function loginToProject(setState, projectId) {
    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/projects/${projectId}`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            document.ert_shadow.location.href="/";
        })
        .catch((error) => {
            console.log(error.message);
        });
}