import React, {useEffect, useState} from 'react';
import Meter from "./Meter";
import Indicator from "../Progress/Indicator";
import {globalQuestionProgress, globalRisk} from "../../../utils/helpers";

export default function Card() {
    const [questionProgress, setQuestionProgress] = useState({});
    const [risk, setRisk] = useState({});

    useEffect(() => {
        function waitForToken() {
            if(Object.keys(globalQuestionProgress).length !== 0 && Object.keys(globalRisk).length !== 0) {
                setQuestionProgress(globalQuestionProgress);
                setRisk(globalRisk);
            } else {
                setTimeout(waitForToken, 250);
            }
        }
        waitForToken()
    }, [])

    return (
        <div className="flex flex-col risk-card min-w-[250px] w-full max-w-[963px] h-fit bg-white rounded  border light-blue-border md:flex-row xl:justify-between xl:max-h-[126px] mb-[48px]">
            <div className="flex  lg:w-[50%] lg:flex-row m-[24px]">
                <Meter riskValue={Object.keys(risk).length !== 0 ? risk.risk_value : 0}/>
                {Object.keys(risk).length !== 0 && risk.risk !== null ?
                    <div className="word-break sm:w-full sm:max-w-[346px] text-left s:text-base text-xs md:inline h-auto self-center ml-[24px]">
                        {Object.keys(risk).length !== 0 ? <div className="font-bold">{risk.risk.title}</div> : <></>}
                        {Object.keys(risk).length !== 0 ? <div>{risk.risk.description}</div> : <></>}
                    </div>
                    :
                    <div className="word-break md:max-w-[346px] text-left text-xs md:inline s:text-base h-auto self-center ml-[24px]">
                        <p>Derzeit besteht kein Risiko</p>
                    </div>
                }

            </div>

            <div className="flex lg:w-[50%]  lg:flex-row m-[24px]">
                <Indicator className="min-w-[124px]" done={Object.keys(questionProgress).length !== 0 ? Math.floor(questionProgress.progress_value) : 0} small={false}/>
                <div className="w-[217px] sm:w-full sm:max-w-[311px] break-all h-auto self-center lg:m-[24px] s:text-base text-xs md:inline ml-[24px] ">
                    {Object.keys(questionProgress).length !== 0 ? <div className="font-bold">{questionProgress.progress.title}</div> : <></>}
                    {Object.keys(questionProgress).length !== 0 ? <div>{questionProgress.progress.description}</div> : <></>}
                </div>
            </div>
        </div>
    );
}