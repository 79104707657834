import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {loginToProject} from "../../utils/api/projects";

export default function ShowDetail() {
    const[project, setProject] = useState([]);
    const currentProject = useLocation().state.current_project;

    const changeProject = (event, projectId) => {
        event.preventDefault();
        loginToProject(project, projectId);
    }

    useEffect(()=> {
        setProject(currentProject)
    },[]);

    return(
        <>
            {project.length !== 0 ?
                   <div className="flex flex-col text-ere-content-blue w-full">

                    <h1 className="text-xl mb-3">Projekt: {project.company}</h1>

                    <table className="min-w-full text-left text-sm font-light">
                        <thead className="border-b bg-white font-medium">
                            <tr className="border-b bg-neutral-100">
                                <th className="px-6 py-4"></th>
                                <th className="px-6 py-4"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Name der Firma</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.company}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Vorname</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.firstname}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Nachname</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.lastname}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Straße</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.street}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Hausnummer</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.housenumber}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">PLZ</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.zipcode}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Ort</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.city}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Land</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.country}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Telefon ( Hier Telefonnummer der Kontaktperson ??? )</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.telephone}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Mobil ( Hier Telefonnummer der Kontaktperson ??? )</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.mobile}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Fax ( #### Benötigen wir hier die Faxnummer der Kontaktperson? #### )</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.fax}</td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">E-Mail fehlt in API-Endpunkt (TODO)</td>
                                <td className="whitespace-nowrap px-6 py-4"></td>
                            </tr>
                            <tr className="border-b bg-neutral-100">
                                <td className="whitespace-nowrap px-6 py-4">Projekt erstellt am</td>
                                <td className="whitespace-nowrap px-6 py-4">{project.created_at}</td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <br />
                    <br />

                    <Link to={-1} className="md:w-fit">
                        <button className="w-full h-full float-right btn hover:btn-secondary btn-secondary-active-and-hover" onClick={(event) => changeProject(event, project.id)}>
                            <span className="icon-chevron-right icon mt-[5px]"/>Zum Projekt wechseln
                        </button>
                    </Link>
                </div>
                :
                <></>
            }
        </>
    );
}