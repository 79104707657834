import React, {useEffect, useState} from "react";
import {Form, Link, useLocation, useNavigate} from "react-router-dom";
import {postMasterData, postTaskDone, postUpload} from "../../utils/api/tasks";
import Upload from "../../components/Content/Task/Upload";
import MasterData from "../../components/Content/Task/MasterData";

export default function ShowDetail() {
    const [task, setTask] = useState([]);
    const currentTask = useLocation().state.current_task;
    const formData = new FormData();
    const navigate = useNavigate();

    let addressInformation = {
        corporate_form: null,
        firstname: null,
        lastname: null,
        company: null,
        street: null,
        housenumber: null,
        zipcode: null,
        city: null,
        country: null,
        telephone: null,
        fax: null,
        mobile: null,
        website: null,
    };

    useEffect(()=> {
        setTask(currentTask)
    },[]);

    function setSessionInformation(event) {
        if(task.task.subtype === "avv") {
            formData.append("file", event.target.form.upload.files[0]);
            formData.append("comment", event.target.form.comment.value);
        } else {
            Object.assign(addressInformation, {
                address_type: task.task.subtype,
                corporate_form: event.target.form.corporate_form.value,
                firstname: event.target.form.firstname.value,
                lastname: event.target.form.lastname.value,
                company: event.target.form.company.value,
                street: event.target.form.street.value,
                housenumber: event.target.form.housenumber.value,
                zipcode: event.target.form.zipcode.value,
                city: event.target.form.city.value,
                country: event.target.form.country.value,
                telephone: event.target.form.telephone.value,
                fax: event.target.form.fax.value,
                mobile: event.target.form.mobile.value,
                website: event.target.form.website.value,
            });
            if(task.task.subtype === "organisation") {
                Object.assign(addressInformation, {
                    managing_director: event.target.form.managing_director.value
                });
            }
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSessionInformation(e)
        if(task.task.subtype === "avv") {
            postUpload(formData, task.task.contracts.id);
        } else {
            postMasterData(addressInformation, task.project_id);
        }
        navigate("/tasks");
    }

    return(
        <>
            {task.length !== 0 ?
                <div className="flex flex-col text-center text-ere-content-blue justify-center content-center w-[90%]">
                    <h1 className="text-xl mb-3">Aufgabe: {task.task.title}</h1>
                    <h2 className="text-lg mb-3">{task.task.risk.title}{task.task.risk.risk_value > 66 ? <span className="icon-attention-red ml-1"/> : <></>} | {task.task.priority.name} | {task.state === "open" ? "Unerledigt" : "Erledigt"}</h2>
                    <p className="mb-10">{task.task.description}</p>

                    <Form method="post" onSubmit={handleSubmit}>
                        {task.task.automatic == 1 ?
                            <>
                                {task.task.subtype === "avv" ?
                                    <Upload/>
                                :
                                    <MasterData task={task}/>
                                }
                            </>
                            :
                            <></>
                        }

                        <div className="flex justify-between items-center gap-3 absolute bottom-6 w-[100%] md:w-[90%] md:right-[6%]">
                            <Link to={-1} className="md:w-fit">
                                <button className="flex items-center justify-center w-full h-full float-left btn hover:btn-secondary btn-secondary-active-and-hover md:w-[148px] max-w-[180px]">
                                    <span className="icon-chevron-left icon mt-[5px]"/>Zurück
                                </button>
                            </Link>
                            { task.state === "closed" && task.task.subtype === null ?
                                <></>
                            :
                                <Link to={-1} className="md:w-fit">
                                    <button type={task.task.automatic == 1 ? "submit" : "button"} onClick={(e) => { task.task.automatic == 1 ? handleSubmit(e) : postTaskDone(task.id) }} className="flex items-center justify-center w-full h-full float-left btn hover:btn-secondary btn-secondary-active-and-hover md:w-[148px] max-w-[180px]">
                                        Erledigt<span className="icon-chevron-right icon mt-[5px]"/>
                                    </button>
                                </Link>
                            }
                        </div>
                    </Form>
                </div>
                :
                <></>
            }
        </>
    );
}