import React from "react";
import {Link} from "react-router-dom";

export default function CategoryOtherActivities({link, state}) {
    return (
        <Link to={link} state={state} className="w-auto md:min-w-[305px] max-h-[140px] h-auto bg-white rounded-sm border border-sky-600 border-opacity-40 flex flex-col py-[38px] break-words justify-center text-center hover:shadow-lg hover:scale-105 transition-all duration-500 hover:bg-ere-category-hover category focus:shadow-lg focus:scale-105 focus:bg-ere-category-hover">
            <div className="flex flex-col">
                <span className="icon-plus-circle text-[36px] mb-[4px]"></span>
                <p className="w-[95%] lg:text-sm text-[14px] text-ere-link-blue font-black uppercase mt-[4px] tracking-[0.233px]">Sonstige Tätigkeiten hinzufügen</p>
            </div>
        </Link>
    )
}