import React from "react";

export default function ListCustom({questionCustomServiceProviders, serviceProviderCategoryId, whenSubmitClicked, whenClicked, displayCreateCustom}) {
    const listCustomServiceProviders = questionCustomServiceProviders.filter(function(provider) {
        if(provider.scope === "custom" && provider.provider_category_id === serviceProviderCategoryId) {
            return provider;
        }}).map((provider) => (
        <p key={provider.id} className="text-ere-grey-alternative mb-2">
            {provider.name}, {provider.street} {provider.housenumber}, {provider.zipcode} {provider.city}, {provider.country}
            <button type="button" onClick={e => whenClicked(e, provider)}><span className="icon-edit ml-2"></span></button>
            <button type="button" onClick={() => whenSubmitClicked(provider.id)}><span className="icon-delete ml-2"></span></button>
        </p>
    ))

    return(
        <div className="mb-6 ">
            {listCustomServiceProviders}
            {displayCreateCustom ?
                <button type="button" onClick={e => whenClicked(e)}>
                    <h3 className="text-sm text-ere-link-blue font-bold uppercase mt-4">Weitere Software hinzufügen <span className="icon-plus-circle"></span></h3>
                </button>
            :
                <></>
            }
        </div>
    );
}