import React from "react";

export default function AlertBox({warnings}) {
    let listWarnings;
    if(warnings) {
        listWarnings = warnings.map((warning) => (
            <li key={warning.id}>{warning.warningtxt}</li>
        ))
    }

    return(
        <>
            { warnings.length !== 0 ?
                <div className=" border-2 border-ere-orange mb-6 p-2 text-ere-orange">
                    <h2 className="text-lg has-tooltip"><span className="icon-attention-red mr-1"/>Achtung</h2>
                    <ul className="list-disc ml-8">
                        {listWarnings}
                    </ul>
                </div>
            :
                <></>
            }
        </>
    );
}