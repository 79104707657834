import {bearerToken, makeAuthenticatedRequest} from "../helpers";

export async function fetchCategories(setState) {

    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/categories`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setState(data);
        })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function fetchCategory(setState, categoryId) {
    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/categories/${categoryId}`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setState(data);
        })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function fetchCategoryQuestions(setState, categoryId) {
    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/questions/category/${categoryId}`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setState(data);
        })
        .catch((error) => {
            console.log(error.message);
        });
}