import {bearerToken} from "../helpers";

export async function fetchTasks(setState) {
    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/tasks`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setState(data);
        })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function postTaskDone(taskId) {
    await fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/tasks/${taskId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        },
        body: JSON.stringify({
            state: "done"
        })
    })
    .catch((error) => {
        console.log(error.message);
    });
}

export async function postUpload(formInformation, contractId) {
    await fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/contracts/${contractId}`, {
        method: 'POST',
        headers: {
            Accept: "multipart/form-data",
            Authorization: bearerToken
        },
        body: formInformation
    }).catch((error) => {
        console.log(error.message);
    })
}

export async function postMasterData(formInformation, projectId) {
    await fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/addresses/${projectId}`, {
        method: 'POST',
        headers: {
            Accept: "application/json, text/javascript, */*; q=0.01",
            Authorization: bearerToken,
        },
        body: JSON.stringify({
            address_type: formInformation.address_type,
            managing_director: formInformation.managing_director,
            corporate_form: formInformation.corporate_form,
            firstname: formInformation.firstname,
            lastname: formInformation.lastname,
            company: formInformation.company,
            street: formInformation.street,
            housenumber: formInformation.housenumber,
            zipcode: formInformation.zipcode,
            city: formInformation.city,
            country: formInformation.country,
            telephone: formInformation.telephone,
            fax: formInformation.fax,
            mobile: formInformation.mobile,
            website: formInformation.website,
        })
    }).catch((error) => {
        console.log(error.message);
    })
}