import React, {useEffect, useState} from "react";
import {fetchCategories} from "../utils/api/categories";
import {bearerToken, checkForChildren, toSlug} from "../utils/helpers";
import Header from "./Header";
import "../assets/css/sidebar.css";
import {Link, NavLink} from 'react-router-dom';
/*import arrows from "../assets/images/arrows.png";*/
import { ReactComponent as ArrowsSvg } from "../assets/icons/arrows.svg";


export default function SidebarComponent({collapsedSidebar, setCollapsedSidebar}) {
    const [arrowsVisible, setArrowsVisible] = useState(true);
    const [categories, setCategories] = useState([]);
    const [activePage, setActivePage] = useState(null);

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };


    useEffect(() => {
        function waitForToken() {
            if (typeof bearerToken !== "undefined") {
                fetchCategories(setCategories)
            } else {
                setTimeout(waitForToken, 250);
            }
        }

        waitForToken()
    }, [])

    function handleActive(event) {
        if (!event.target.classList.value.includes("active")) {
            event.target.parentElement.classList.toggle('active');
            event.target.classList.toggle('active');

            if (activePage) {
                activePage.parentElement.classList.remove("active");
                activePage.classList.remove("active");
            }
            setActivePage(event.target);
        }
    }


    //TODO: Sobald API funktioniert, umschreiben (Array.from(Object.values(categories)))
    const listCategories = Array.from(Object.values(categories)).map(category => {
        if (checkForChildren(category)) {
            return (

                <NavLink
                    to={`/categories/${category.id}/${toSlug(category.title)}`} //I want to render link dinamically:instead of {`/tasks`} use {`/categories/${category.id}/${toSlug(category.title)}`} :
                    className="h-[40px] w-[120px] level-1 flex items-center pl-[46px] hover:text-ere-link-blue "
                    key={category.id}
                    onClick={() => {
                        if (!collapsedSidebar) {
                            setCollapsedSidebar(!collapsedSidebar);
                            setArrowsVisible(!arrowsVisible);
                        } else {
                            setCollapsedSidebar(true);


                        }
                    }}
                >
                    <span
                        className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>{category.title}</span>
                </NavLink>

            )
        }
    });


    const [open, setOpen] = useState(false);
    return (


        <div className="sidebar relative min-h-[724px] shadow-sm bg-white hidden lg:block">
            {arrowsVisible ? <span
                    className="icon-collapse-sidebar w-6 h-6 rounded relative top-[22px] right-[-285px] cursor-pointer rotate-180 mr-[-10px] z-20  animate-arrows delay-500 "
                    onClick={() => {
                        setCollapsedSidebar(!collapsedSidebar);
                        setArrowsVisible(!arrowsVisible);
                    }}>

                    {/*<img src="../../src/assets/icons/arrows.svg" alt="arrows" />*/}
                    <ArrowsSvg className="w-[34px] h-[34px] mr-[-2px]"/>

                    {/*<img className="w-[30px] h-[30px] mr-[-2px]"  src={process.env.REACT_APP_URL + arrows} alt="arrows"/>*/}
                    </span>


                : <div className="w-[30px] h-[30px]"></div>}


            <div className={`sidebar h-[full] top-[-30px] rounded-sm overflow-y-auto duration-[300ms] transition-all ease-linear 
               sidebar text-[14px]  ${collapsedSidebar ? "w-[72px] h-[694px] overflow-y-hidden" : "w-[295px] overflow-x-hidden"}`}>

                <Header className="header1 font-[600] " collapsed={collapsedSidebar}
                        collapsedFunction={() => setCollapsedSidebar(!collapsedSidebar)} arrowsVisible={arrowsVisible}
                        arrowsVisibleFunction={() => setArrowsVisible(!arrowsVisible)}></Header>
                <div
                    className={`menu text-ere-grey h-auto text-base flex flex-col  ${collapsedSidebar ? "mt-[49px]" : "mt-[30px] animate-appear animate-menu duration-300 delay-100 "}`}>

                    <NavLink to="/" className="menu-item level-0">
                        <span className="icon-dashboard icon"/>

                        <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Dashboard</span>


                    </NavLink>


                    <div className=" sub-menu-wrapper menu-item  relative block">
                        <div className="flex  level-0 " onClick={(e) => {
                            {
                                setOpen(!open);
                                handleActive(e);

                            }
                        }}>
                            <Link to={"/categories"}>
                                <span className="icon-basisdatenschutz icon"/>
                                <span
                                    className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Basisdatenschutz</span>
                            </Link>
                            {collapsedSidebar ? "" : <span
                                className={open ? "icon-chevron-down icon ml-[70px] rotate-180 h-min transition-all duration-300 scale-[0.8]" : "icon-chevron-down icon ml-[70px] scale-[0.8] h-min transition-all duration-300"}></span>}
                        </div>
                        {collapsedSidebar ? "" : <div className={open ? "sub-menu open " : "closed"}>
                            {listCategories}
                        </div>}
                    </div>
                    {/*


                    <div className="relative block sub-menu-wrapper menu-item ">
                        <NavLink to="/" className="level-0 inline-block w-full">
                            <span className="icon-erweiterter-schutz icon"/>
                            <span className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Erweiterter Schutz</span>

                        </NavLink>

                    </div>*/}
                    <NavLink to="/" className="">
                        <li onClick={handleActive} className="menu-item level-0"><span
                            className="icon-erweiterter-schutz icon"/><span
                            className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Erweiterter Schutz</span>
                        </li>
                    </NavLink>

                    <NavLink to="/tasks" className="">
                        <li onClick={handleActive} className="menu-item level-0"><span
                            className="icon-aufgaben icon"/><span
                            className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Aufgaben</span></li>
                    </NavLink>
                    <NavLink to="/contracts" className="">
                        <li onClick={handleActive} className="menu-item level-0"><span
                            className="icon-dokumente icon"/><span
                            className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Dokumente & Verträge</span>
                        </li>
                    </NavLink>
                    <a href="/" className="">
                        <li onClick={handleActive} className="menu-item level-0"><span
                            className="icon-einstellungen icon"/><span
                            className={`category-name ${collapsedSidebar ? "hidden" : ""}`}>Einstellungen</span>
                        </li>
                    </a>


                </div>

                {collapsedSidebar ? <button
                    role="link"
                    onClick={() => openInNewTab('https://www.e-recht24.de/mitglieder/dsgvo-notfall/')}
                    className="btn btn-cta hover:btn-cta-active-and-hover text-[14px] w-[42px] h-[45px] p-[3px] left-[16px] bottom-[24px] sticky mt-[300px]">
                    <span className="icon-attention-white p-[5px]"></span><span className="hidden uppercase">dsgvo-notfall melden</span>
                </button> : <button
                    role="link"
                    onClick={() => openInNewTab('https://www.e-recht24.de/mitglieder/dsgvo-notfall/')}
                    className="btn btn-cta hover:btn-cta-active-and-hover text-[14px] w-[247px] h-[45px] p-[3px] absolute  mb-[24px] ml-[23px] bottom-[5px]">
                        <span
                            className="icon-attention-white p-[5px] text-center "></span><span className="uppercase">dsgvo-notfall melden</span>
                </button>}
            </div>
        </div>);
}