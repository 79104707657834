import React from "react";
import {Link} from "react-router-dom";



export default function Breadcrumb() {


    return(
        <div className="mb-[10px] text-[14px] breadcrumb">
            <Link to={"/"}>Dashboard - Datenschutzlösung ></Link>

        </div>
    );
}
/*const location = useLocation();

    let currentLink ='';

    const crumbs = location.pathname.split('/')

        .filter(crumb => crumb !== '')
        .map(crumb => {
            currentLink += `/${crumb}`;

            return (
                <div className="crumb" key={crumb}>
                    <Link to={currentLink}>{isNaN(crumb) ? "" : <span>{crumb}</span>}</Link>
                </div>
            )
        })*/