import React, {useEffect, useState} from "react";
import {Form, useLocation, useNavigate} from "react-router-dom";
import {getServiceProviders, toSlug, getCheckedCollection, getPrefilledAdditionalData, bearerToken} from "../../../utils/helpers";
import {fetchAnswer, postAnswer} from "../../../utils/api/answer";
import {fetchCategory, fetchCategoryQuestions} from "../../../utils/api/categories";
import {deleteCustomServiceProvider, fetchServiceProviders, postCustomServiceProvider, updateCustomServiceProvider} from "../../../utils/api/serviceProviders";
import ListElement from "../../../components/Content/Answer/ListElement";
import ServiceProvider from "../../../components/Content/Answer/ServiceProvider";
import YesNo from "../../../components/Content/Answer/YesNo";
import Textfield from "../../../components/Content/Answer/Textfield";
import Bar from "../../../components/Content/Progress/Bar";
import ListCustom from "../../../components/Content/Answer/CustomServiceProvider/ListCustom";
import FormCustom from "../../../components/Content/Answer/CustomServiceProvider/FormCustom";
import AlertBox from "../../../components/Content/Answer/Warning/AlertBox";
import PopupQuestion from "../../../components/Content/Answer/Hints/PopupQuestion";
import AdditionalTextfield from "../../../components/Content/Answer/Additional/AdditionalTextfield";

export default function QuestionCatalog() {
    const navigate = useNavigate();
    const currentIndex = useLocation().state.current_index;

    // Question
    const currentQuestion = useLocation().state.current_question;
    const currentCategoryQuestions = useLocation().state.current_category_questions;
    const [question, setQuestion] = useState([]);
    const [allCategoryQuestions, setAllCategoryQuestions] = useState([]);
    const [categoryQuestions, setCategoryQuestions] = useState([]);
    let addSubQuestions = false;

    // Category
    const currentCategory = useLocation().state.current_category;
    const [forCurrentCategory, setForCurrentCategory] = useState([]);
    const [categoryForProgress, setCategoryForProgress] = useState([]);

    // Answer
    const [prefilledAnswer, setPrefilledAnswer] = useState([]);
    const [additionalAnswer, setAdditionalAnswer] = useState([]);

    // Provider
    const [serviceProviders, setServiceProviders] = useState([]);
    const [formCustomServiceProvider, setFormCustomServiceProvider] = useState(<></>);
    const [customServiceProviderId, setCustomServiceProviderId] = useState();
    const [displayCreateCustom, setDisplayCreateCustom] = useState(true);

    // Additional Information
    const [alertBox, setAlertBox] = useState(<></>)
    const [warning, setWarning] = useState([])
    const [formAction, setFormAction] = useState("submit_answer");

    let sessionInformation = {question_type: question.answertype, answer: null, additional_text: null, additional_data: null};
    let customProviderInformation = {company: null, street: null, housenumber: null, zipcode: null, city: null, country: null, eu: null, provider_category_id: null}

    useEffect(()=> {
        function waitForToken() {
            if(typeof bearerToken !== "undefined") {
                fetchCategoryQuestions(setAllCategoryQuestions,currentCategory.id);
                fetchCategory(setCategoryForProgress, currentCategory.id);
                fetchAnswer(setPrefilledAnswer, currentQuestion.id);
                if(question.answertype === "serviceprovider") {
                    fetchServiceProviders(setServiceProviders);
                }
                setForCurrentCategory(currentCategory);
            } else {
                setTimeout(waitForToken, 250);
            }
        }
        waitForToken()
        setCategoryQuestions(currentCategoryQuestions);
        setQuestion(currentQuestion);
        setAdditionalAnswer([]);
        setAlertBox(<AlertBox warnings={warning}/>);
    },[currentCategoryQuestions, currentQuestion, currentCategory, question.answertype, setServiceProviders, formCustomServiceProvider, customServiceProviderId, warning]);

    function displayAnswer(answerType) {
        let answer;
        if(answerType === "yesno") {
            answer = <YesNo questionAnswers={question.answers} whenSelected={handleCheck} prefilled={prefilledAnswer.answer}/>
        } else if(answerType === "listelement") {
            answer = <ListElement questionAnswers={question.answers} whenChecked={handleCheck} prefilled={prefilledAnswer.answer} singleOrMultiple={question.subtype}/>
        } else if (answerType === "serviceprovider") {
            answer = <ServiceProvider questionAnswers={getServiceProviders(question)} whenChecked={handleCustomServiceProvider} prefilled={prefilledAnswer.answer}/>
        } else if(answerType === "textfield") {
            answer = <Textfield texttype={question.answers[0].subtype} prefilled={prefilledAnswer.additional_text}/>
        } else {
            answer = <p>Es ist keine Antwort hinterlegt</p>
        }
        return answer;
    }

    function handleAdditionalAnswer(additionalAnswer) {
        let additional;
        if(additionalAnswer.length === 0 || additionalAnswer.length === undefined) {
            if(additionalAnswer.subtype === "textarea") {
                additional = <AdditionalTextfield texttype="textarea" prefilled={getPrefilledAdditionalData(prefilledAnswer)}/>
            } else if(additionalAnswer.subtype === "none") {
                additional = <></>
            } else if(additionalAnswer.subtype === "questionpool") {
                addSubQuestions = true;
                additional = <></>
            } else if(getPrefilledAdditionalData(prefilledAnswer) && getPrefilledAdditionalData(prefilledAnswer).length !== 0 && getPrefilledAdditionalData(prefilledAnswer)[0].text !== "") {
                additional = <AdditionalTextfield texttype="textarea" prefilled={getPrefilledAdditionalData(prefilledAnswer)}/>
            }
        } else {
            additionalAnswer.forEach((addAnswer) => {
                if(addAnswer.subtype === "questionpool") {
                    addSubQuestions = true;
                }
            })
        }
        return additional;
    }

    function displayCustomServiceProvider() {
        if(question.answertype === "serviceprovider") {
            return <ListCustom
                    questionCustomServiceProviders={serviceProviders}
                    serviceProviderCategoryId={getServiceProviders(question)[0].provider_category_id}
                    whenSubmitClicked={handleDelete}
                    whenClicked={handleCustomServiceProvider}
                    displayCreateCustom={displayCreateCustom}
            />
        } else {
            return <></>
        }
    }

    function handleCustomServiceProvider(input, prefilledProvider) {
        if(input.target.checked) {
            setFormCustomServiceProvider(<FormCustom whenSaved={handleFormAction} whenClosed={closeCustomForm}/>);
            setDisplayCreateCustom(false);
        } else if(input.type === "click" && prefilledProvider) {
            setFormCustomServiceProvider(<FormCustom whenSaved={handleFormAction} whenClosed={closeCustomForm} prefilled={prefilledProvider} hasEdit={true}/>);
            setDisplayCreateCustom(false);
        } else if(input.type === "click") {
            setFormCustomServiceProvider(<FormCustom whenSaved={handleFormAction} whenClosed={closeCustomForm}/>);
            setDisplayCreateCustom(false);
        } else {
            setFormCustomServiceProvider(<></>);
            setDisplayCreateCustom(true);
        }
    }

    function handleCheck(answer, checkbox) {
        if(question.subtype === "multiselect") {
            if(checkbox.target.checked) {
                if(additionalAnswer.length === 0) {
                    setAdditionalAnswer([answer]);
                } else {
                    setAdditionalAnswer([...additionalAnswer, answer]);
                }
                if(answer.warningtxt !== null && answer.warningtxt !== '') {
                    warning.push(answer);
                    setAlertBox(<AlertBox warnings={warning}/>);
                }
            } else {
                setWarning(warning.filter((filteredAnswer) => filteredAnswer.id !== answer.id))
            }
        } else {
            if(checkbox.target.checked) {
                setAdditionalAnswer(answer);
                if(answer.warningtxt !== null && answer.warningtxt !== '') {
                    warning.shift();
                    warning.push(answer);
                    setAlertBox(<AlertBox warnings={warning}/>);
                } else {
                    setAlertBox(<></>);
                }
            } else {
                setAdditionalAnswer([]);
                setAlertBox(<></>);
            }
        }
    }

    function handleFormAction(action, updatedServiceProviderId) {
        setFormAction(action)
        if(updatedServiceProviderId) {
            setCustomServiceProviderId(updatedServiceProviderId);
        }
    }

    function checkForPrefilledSubquestions() {
        if(question.length !== 0 && question.answers !== undefined) {
            let answer = question.answers.find((answer) => answer.id == prefilledAnswer.answer);
            return !!(answer && answer.id && answer.subtype === "questionpool");
        }
    }

    function setSessionInformation(event) {
        if(question.answertype === "yesno") {
            Object.assign(sessionInformation, {
                question_id: question.id,
                answer: event.target.yesno.value,
            });
            if(event.target.textfield !== undefined && event.target.textfield !== "") {
                Object.assign(sessionInformation, {additional_data: [{
                    answer: event.target.yesno.value,
                    text: event.target.textfield.value
                }]});
            }
        } else if(question.answertype === "listelement") {
            Object.assign(sessionInformation, {
                question_id: question.id,
                answer: getCheckedCollection(question),
                additional_data: []
            });
            if(event.target.textfield !== undefined) {
                // TODO: get answer dynamically
            }
        } else if (question.answertype === "serviceprovider") {
            let customServiceProviders = serviceProviders.filter((provider) => provider.scope === "custom" && provider.provider_category_id === getServiceProviders(question)[0].provider_category_id).map(provider => provider.id)
            Object.assign(sessionInformation, {
                question_id: question.id,
                answer: getCheckedCollection(question, customServiceProviders),
            });
        } else if(question.answertype === "textfield") {
            Object.assign(sessionInformation, {
                question_id: question.id,
                additional_text: event.target.textfield.value,
            });
        }
    }

    function setCustomProviderInformation(event) {
        Object.assign(customProviderInformation, {
            company: event.target.company.value,
            street: event.target.street.value,
            housenumber: event.target.housenumber.value,
            zipcode: event.target.zipcode.value,
            city: event.target.city.value,
            country: event.target.country.value,
            eu: event.target.eu.value,
            provider_category_id: getServiceProviders(question)[0].provider_category_id
        });
        resetCustomProviderInformation(event)
    }

    function resetCustomProviderInformation(event) {
        event.target.company.value = "";
        event.target.street.value = "";
        event.target.housenumber.value = "";
        event.target.zipcode.value = "";
        event.target.city.value = "";
        event.target.country.value = "";
        event.target.eu.value = "";
    }

    function closeCustomForm() {
        setFormCustomServiceProvider(<></>);
        setDisplayCreateCustom(true);
    }

    function handleDelete(customServiceProviderId) {
        let delete_confirm = window.confirm('Möchten Sie diese Software wirklich löschen?')
        if(delete_confirm) {
            deleteCustomServiceProvider(customServiceProviderId)
        }
        fetchServiceProviders(setServiceProviders);
        setFormCustomServiceProvider(<></>);
        setDisplayCreateCustom(true);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if(formAction === "submit_answer") {
            setSessionInformation(e)
            if(addSubQuestions) {
                if(additionalAnswer.length === 0 || additionalAnswer.length === undefined) {
                    let subQuestions = additionalAnswer.target.object.map(question => allCategoryQuestions.find(index => index.id === question.id))
                    categoryQuestions.splice(currentIndex+1, 0, ...subQuestions);
                } else {
                    additionalAnswer.forEach((addAnswer) => {
                        let subQuestions = addAnswer.target.object.map(question => allCategoryQuestions.find(index => index.id === question.id))
                        categoryQuestions.splice(currentIndex+1, 0, ...subQuestions);
                    })
                }
            } else if(checkForPrefilledSubquestions() && additionalAnswer.length === 0) {
                if(additionalAnswer.length === 0 || additionalAnswer.length === undefined) {
                    let answer = question.answers.find((answer) => answer.id == prefilledAnswer.answer);
                    let subQuestions = answer.target.object.map(question => allCategoryQuestions.find(index => index.id === question.id))
                    categoryQuestions.splice(currentIndex+1, 0, ...subQuestions);
                } else {
                    prefilledAnswer.answer.split(",").forEach((answerId) => {
                        let answer = question.answers.find((answer) => answer.id == answerId);
                        let subQuestions = answer.target.object.map(question => allCategoryQuestions.find(index => index.id === question.id))
                        categoryQuestions.splice(currentIndex+1, 0, ...subQuestions);
                    })
                }
            }
            if((sessionInformation.question_type !== "textfield" && sessionInformation.answer !== "") || (sessionInformation.question_type === "textfield" && sessionInformation.additional_text !== "")) {
                postAnswer(sessionInformation);
            }
            fetchAnswer(setPrefilledAnswer, currentQuestion.id)
            handleAdditionalAnswer(additionalAnswer)
            setAdditionalAnswer([]);
            setAlertBox(<></>);
            setWarning([]);
            sessionInformation = {};
            addSubQuestions = false;
            if(categoryQuestions[currentIndex+1]) {
                navigate(`/categories/${forCurrentCategory.id}/${toSlug(forCurrentCategory.title)}/questions/${categoryQuestions[currentIndex+1].id}/${toSlug(categoryQuestions[currentIndex+1].title)}`,
                    {
                        state: {
                            current_category: forCurrentCategory,
                            current_question: categoryQuestions[currentIndex+1],
                            current_category_questions: categoryQuestions,
                            current_index: currentIndex+1
                        }
                    }
                );
            } else {
                navigate("/categories");
            }
        } else if(formAction === "submit_custom_service_provider") {
            setCustomProviderInformation(e);
            postCustomServiceProvider(customProviderInformation);
            fetchServiceProviders(setServiceProviders);
            setDisplayCreateCustom(true);
        } else if(formAction === "edit_custom_service_provider") {
            setCustomProviderInformation(e);
            updateCustomServiceProvider(customProviderInformation, customServiceProviderId);
            fetchServiceProviders(setServiceProviders);
            setDisplayCreateCustom(true);
        }
        setFormCustomServiceProvider(<></>);
    }

    function handleReturn() {
        setWarning([]);
        setFormCustomServiceProvider(<></>);
        navigate(-1);
    }

    return(
        <div className="flex flex-col text-center text-ere-content-blue justify-center content-center w-full">
            <p>{currentIndex+1} / {categoryQuestions.length}</p>
            <h1 className="text-xl mb-3">{forCurrentCategory.title}</h1>
            {question.hint ?
                <PopupQuestion questionTitle={question.title} popupAnswer={question.hint} />
            :
                <h2 className="text-base md:text-lg has-tooltip w-fit self-center flex content-center relative cursor-pointer">
                    {question.title}
                </h2>
            }
            {question.description ?
                <h3 className="text-sm md:text-base">{question.description}</h3>
                :
                <></>
            }
            {question.subtype === "multiselect" ?
                <h3 className="text-sm md:text-base">(Mehrfachauswahl möglich)</h3>
                :
                <></>
            }
            <div className="mb-8"/>
            <Form method="post" onSubmit={handleSubmit} className="flex flex-col text-start flex-nowrap lg:pb-[10%] pb-[40%]">
                {displayAnswer(question.answertype)}
                {handleAdditionalAnswer(additionalAnswer)}
                {displayCustomServiceProvider()}
                {formCustomServiceProvider}
                {alertBox}
                <div className="text-sm flex justify-between items-center gap-3 absolute bottom-6 w-[100%] md:w-[90%] md:right-[6%]">
                    <div className="md:w-fit w-[50%] h-10">
                        <button type="button" onClick={() => {handleReturn()}} className="flex items-center justify-center w-full h-full float-left btn hover:btn-secondary btn-secondary-active-and-hover md:w-[148px] p-[14px] max-w-[180px]">
                            <span className="icon-chevron-left icon pt-[5px]"/>Zurück
                        </button>
                    </div>
                    <button type="submit" className="flex items-center justify-center h-10 float-right place-self-center btn hover:btn-secondary btn-secondary-active-and-hover md:w-[148px] p-[14px] max-w-[180px] w-[50%]" onClick={() => handleFormAction("submit_answer")}>
                        {currentIndex+1 === categoryQuestions.length ? "Abschließen" : "Weiter" }<span className="icon-chevron-right font-base icon pt-[5px]"/>
                    </button>
                </div>
            </Form>
            { categoryForProgress.length !== 0 ?
                <Bar done={Math.floor(categoryForProgress[0].answer_progress.progress_value)}/>
            :
                <></>
            }
        </div>
    );
}