import {bearerToken, setGlobalQuestionProgress, setGlobalRisk} from "../helpers";

export async function fetchGlobalStats() {
    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/globalstats`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setGlobalQuestionProgress(data.question)
            setGlobalRisk(data.risk)
        })
        .catch((error) => {
            console.log(error.message);
        });
}