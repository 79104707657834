import React, {useEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Appointment from "../components/Cards/Appointment";
import Report from "../components/Cards/Report";
import Breadcrumb from "./Breadcrumb";
import {fetchToken} from "../utils/api/token";
import {fetchGlobalStats} from "../utils/api/globalStats";

export default function Main() {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();

    useEffect(() => {
        (async () =>  {
            await fetchToken()
            await fetchGlobalStats()
        })()
    }, [location])
    return (
        <>
            <div className="main-wrapper xl:py-[54px] xl:px-[60px] flex justify-center lg:p-6 w-full pb-[100px] lg:pb-[120px] bg-white lg:bg-ere-background-grey  ">
                <div className="flex flex-col items-center xl:bg-none w-full lg:w-auto lg:scale-[0.78] xl:scale-[1]">
                    <div className="breadcrumb-wrapper self-start md:self-start">
                        <Breadcrumb></Breadcrumb>
                    </div>
                    <div className="flex h-auto content-wrapper w-full justify-center">
                        <Sidebar collapsedSidebar={collapsed} setCollapsedSidebar={setCollapsed}/>
                        <div className="relative w-full">
                        <div className="bg-white outlet-wrapper p-2 lg:p-6 lg:ml-6 lg:w-[1011px] min-h-[724px] h-fit lg:shadow-sm flex flex-col items-center md:justify-start  relative align-baseline ">
                            <Outlet/>
                        </div>
                        </div>
                        { collapsed ?
                            <div className="hidden sidebar-right min-h-[724px] ml-[24px] lg:flex flex-col space-y-[24px] animate-appear -z-10">
                                <Report/>
                                <Appointment/>
                            </div>
                        :
                            <div className="animate-disappear"></div>

                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
/*
return (
    <>
        <div className="main-wrapper py-[54px] px-[60px] flex justify-center xl:w-full xl:h-full p-0  ">
            <div className="flex flex-col">
                <div className="breadcrumb-wrapper 2xl:ml-[50px]">
                    <Breadcrumb></Breadcrumb>
                </div>
                <div className="flex h-auto content-wrapper 2xl:ml-[50px] xl:ml-0">
                    <Sidebar collapsed={collapsed} setCollapsed={setCollapsed}/>
                    <div className="shadow-xl bg-white outlet-wrapper p-[24px] min-h-[724px] relative min-w-[850px] w-[1011px] ml-[24px] xl:w-full xl:ml-0 h-full
                        ">
                        <Outlet/>
                        <Bar done={90}/>
                    </div>
                    { collapsed ?
                        <div className="sidebar-right h-[724px] ml-[24px] flex flex-col space-y-[24px] transition-all duration-6000 ease-in-out 2xl:hidden xl:hidden">
                            <Report/>
                            <Appointment/>
                        </div>
                        :
                        ""
                    }
                </div>
            </div>
        </div>
        <Footer/>
    </>
);*/
