import {bearerToken} from "../helpers";

export async function fetchServiceProviders(setState) {
    fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/service-providers`,{
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }})
        .then(response => response.json())
        .then((data) => {
            setState(data);
        })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function postCustomServiceProvider(formInformation) {
    await fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/service-providers`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        },
        body: JSON.stringify({
            company: formInformation.company,
            street: formInformation.street,
            housenumber: formInformation.housenumber,
            zipcode: formInformation.zipcode,
            city: formInformation.city,
            country: formInformation.country,
            eu: formInformation.eu,
            provider_category_id: formInformation.provider_category_id
        })
    })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function updateCustomServiceProvider(formInformation, serviceProviderId) {
    await fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/service-providers/${serviceProviderId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        },
        body: JSON.stringify({
            company: formInformation.company,
            street: formInformation.street,
            housenumber: formInformation.housenumber,
            zipcode: formInformation.zipcode,
            city: formInformation.city,
            country: formInformation.country,
            eu: formInformation.eu,
            provider_category_id: formInformation.provider_category_id
        })
    })
        .catch((error) => {
            console.log(error.message);
        });
}

export async function deleteCustomServiceProvider(serviceProviderId) {
    await fetch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/service-providers/${serviceProviderId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: bearerToken
        }
    })
        .catch((error) => {
            console.log(error.message);
        });
}