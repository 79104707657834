import React from "react";
import {Link} from "react-router-dom";
import "../assets/css/footer.css";

export default function Footer(){
    return(
        <div className="fixed bottom-nav min-h-[82px] bottom-0 w-full
         bg-ere-blue px-[20px] text-ere-nav-mobile-blue z-99 overflow-hidden lg:hidden text-sm">
            <div className="flex justify-around mt-[30px] xxs:mt-[15px] ">
                <Link className="flex flex-col text-center justify-between active-blue pr-1" to={`/`}>
                    <span className="icon-dashboard icon"></span>
                    <span className="hidden xs:inline text-xs text-ere-nav-mobile-blue nav-icon-description">Dashboard</span>
                </Link>
                <Link className="flex flex-col text-center justify-between pr-1" to={`/categories`}>
                    <span className="icon-basisdatenschutz icon"></span>
                    <span className="hidden xs:inline text-xs text-ere-nav-mobile-blue nav-icon-description">Datenschutz</span>
                </Link>
                <Link className="flex flex-col text-center justify-between pr-1" to={`/`}>
                    <span className="icon-aufgaben icon"></span>
                    <span className="hidden xs:inline text-xs text-ere-nav-mobile-blue nav-icon-description">Aufgaben</span>
                </Link>
                <Link className="flex flex-col text-center justify-between pr-1" to={`/`}>
                    <span className="icon-dokumente icon"></span>
                    <span className="hidden xs:inline text-xs text-ere-nav-mobile-blue nav-icon-description">Dokumente</span>
                </Link>
                <Link className="flex flex-col text-center justify-between pr-1" to={`/`}>
                    <span className="icon-einstellungen icon"></span>
                    <span className="hidden xs:inline text-xs text-ere-nav-mobile-blue nav-icon-description">Einstellungen</span>
                </Link>
            </div>
        </div>
    )
}