export let bearerToken;
export let globalRisk = {};
export let globalQuestionProgress = {};
export let loggedInUser;

export function setBearerToken(token) {
    bearerToken = "Bearer " + token;
}

export function setLoggedInUser(user) {
    loggedInUser = user;
}

export function setGlobalRisk(risk) {
    globalRisk = risk;
}

export function setGlobalQuestionProgress(question) {
    globalQuestionProgress = question;
}

export function toSlug(str) {
    if(str) {
        return str.toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');
    } else {
        return null;
    }
}

export function checkForChildren(category) {
    if(category.children !== undefined) {
        return category.children.length > 0;
    }
}

export function getServiceProviders(question) {
    if(question.answers !== undefined) {
        return question.answers[0].target.object
    }
}

export function getPrefilledAdditionalData(prefilledAnswer) {
    if(prefilledAnswer !== undefined) {
        return prefilledAnswer.additional_data
    }
}

export function getCheckedCollection(question, customServiceProviders) {
    let collection = '';
    let checkedElements = [];
    let elements;
    let answertype;

    if(question.answertype === "listelement") {
        answertype = 'list_element';
    } else {
        answertype = 'service_provider'
    }

    // elements = document.getElementsByName(answertype)
    elements = document.ert_shadow.querySelectorAll('[name="' + answertype + '"]')

    for (var i=0;i<elements.length;i++){
        if ( elements[i].checked ) {
            checkedElements.push(elements[i]);
        }
    }

    for (let element of checkedElements) {
        if(collection === '') {
            collection = element.value
        } else {
            collection = collection.concat(',',element.value)
        }
    }

    if(customServiceProviders) {
        for (let element of customServiceProviders) {
            let num = element.toString()
            if(collection === '') {
                collection = num;
            } else {
                collection = collection.concat(',', num);
            }
        }
    }
    return collection;
}


export function getCookieContentByName(cookieName) {
    let name = cookieName + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function checkIfCookieExists(cookieName) {
    let checkCookie = getCookieContentByName(cookieName);
    if (checkCookie !== "") {
       return true;
    } else {
        return false;
    }
}

export function setCookie(cookieName, cookieValue, expiredDays) {
    const d = new Date();
    d.setTime(d.getTime() + (expiredDays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expiredDays + ";path=/";
}

// Wird von allen fetch-Funktionen aufgerufen, dieser enhält die Logik, wenn AccessToken abgelaufen ist.

//   authenticated requests
export async function makeAuthenticatedRequest (url, additionalHeaders) {

    let attemptCount = 0;
    const maxAttempts = 2;

    const defaultHeaders = {
        'Authorization': `${bearerToken}`,  // Bearer token
        'Accept': 'application/json'
    };
    console.log('while loop before attemptCount :',attemptCount)

    while (attemptCount < maxAttempts) {

        const headers = { ...defaultHeaders, ...additionalHeaders };
        // fetch data
        let response = await fetch(url, { headers });
        attemptCount++;

        // unauthorized access status 401
        if (response.status === 401 && attemptCount === 0 ) {
            console.log("response:",response.status)

            // new access token
           // await fetchAccessToken();

            // fetch data
            response = await fetch(url, { headers: { ...headers, 'Authorization': `${bearerToken}` } });
            // headers['Authorization'] = `Bearer ${authToken}`;   // authToken Bearer token
            console.log(' inside while loop attemptCount:',attemptCount)
        } else {
            return response.json();
        }
    }
    throw new Error('Unable to authenticate after retrying.');
};