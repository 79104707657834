import React, {useEffect, useState} from "react";
import '../../../index.css'
import Popup from "./Hints/Popup";

export default function ListElement({questionAnswers, whenChecked, prefilled, singleOrMultiple}) {
    const [answers, setAnswers] = useState([]);

    useEffect(()=> {
        setAnswers(questionAnswers);
        document.ert_shadow.querySelectorAll('[name="list_element"]').forEach((element) => {
            if(prefilled && element.value == prefilled.split(",").find((id) => id == element.value)) {
                element.checked = true;
            }
        })
    },[questionAnswers, prefilled])

    const listAnswers = answers?.map((answer) => (
        <label key={answer.id} className={`relative cursor-pointer has-tooltip transition-all active:scale-95 peer-checked:ring-ere-checkbox-blue 
        flex justify-center overflow-visible min-w-[180px] w-full  max-w-[195px] max-h-[107px] h-auto min-h-[100px]
        p-3 shadow-md rounded-sm border border-sky-600 border-opacity-40 text-base items-center ${answer.tooltip ? "z-30" : "z-10"}`}>
            <input type={singleOrMultiple === "multiselect" ? "checkbox" : "radio"} className="peer sr-only" value={answer.id} name="list_element" onChange={(e) => whenChecked(answer, e)}/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px]">
               <div className="bg-ere-checkbox-blue  w-[24px] text-center rounded-full text-[20px]">
                    <span className="icon-check"/>
                </div>
            </span>
            { answer.tooltip ?
                <Popup popupAnswer={answer.tooltip}/>
                :
                <></>
            }
            <div className={`${prefilled && answer.id === prefilled.split(",").find((id) => id === answer.id) ? "ring-ere-checkbox-blue" : "ring-ere-link-blue"}`}>
                <header className="flex flex-col justify-center items-center">
                    <p className="w-full">{answer.title}</p>
                </header>
            </div>
        </label>
    ))

    return(
        <div className="md:flex flex-wrap justify-center grid grid-cols-1 gap-5  s:grid-cols-2 md:gap-3 mb-6 w-max md:w-fit self-center">
            {listAnswers}
        </div>
    );
}