import '../../../index.css';

export default function GradientSVG() {
    const idCSS = "hello";
    const gradientTransform = `rotate(90)`;
    return (
        <svg style={{ height: 0 }} className="first-svg">
            <defs>
                <linearGradient id={idCSS} gradientTransform={gradientTransform}>
                    <stop offset="0.25" stopColor="#3DC6EF" />
                    <stop offset="0.9995" stopColor="#0094D8" />
                </linearGradient>
            </defs>
        </svg>
    );
}

