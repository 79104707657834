import React from "react";

export default function MasterData({task}) {
    return (
        <div className="flex flex-col text-start flex-nowrap lg:pb-[10%] pb-[40%]">
            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full px-3">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-password">Firmenname</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="company" type="text" required/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Vorname</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="firstname" type="text" required/>
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-last-name">Nachname</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="lastname" type="text" required/>
                </div>
            </div>
            { task.task.subtype === "organisation" ?
                <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-password">Geschäftsführer</label>
                        <textarea className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="managing_director" rows="4"/>
                    </div>
                </div>
            :
                <></>
            }
            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Straße</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="street" type="text" required/>
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-last-name">Hausnummer</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="housenumber" type="text" required/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Postleitzahl</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="zipcode" type="text" required/>
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-last-name">Ort</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="city" type="text" required/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full px-3">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-password">Land</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="country" type="text" required/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Telefon</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="telephone" type="text" required/>
                </div>
                <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Fax</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="fax" type="text" required/>
                </div>
                <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Mobiltelefon</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="mobile" type="text" required/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-first-name">Website</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="website" type="text" required/>
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block tracking-wide text-ere-content-blue text-sm font-bold mb-2" htmlFor="grid-last-name">Gesellschaftsform</label>
                    <input className="appearance-none text-ere-content-blue block w-full border border-ere-checkbox-blue py-3 px-4 leading-tight focus:outline-none" name="corporate_form" type="text" required/>
                </div>
            </div>
        </div>
    );
}