import React, {useEffect, useState} from "react";
import {fetchContractFile, fetchContracts} from "../../../utils/api/contracts";
import {bearerToken} from "../../../utils/helpers";
import {Link} from "react-router-dom";
import {fetchTasks} from "../../../utils/api/tasks";

export default function Overview() {
    const [tasks, setTasks] = useState([])
    const [contracts, setContracts] = useState([]);

    useEffect(() => {
        function waitForToken() {
            if(typeof bearerToken !== "undefined") {
                fetchTasks(setTasks)
                fetchContracts(setContracts);
            } else {
                setTimeout(waitForToken, 250);
            }
        }
        waitForToken()
    },[])

    function downloadContract(contract) {
        fetchContractFile(contract)
    }

    const listContracts = contracts?.map(contract => {
        const updatedAt = new Date(contract.uploaded_at)
        const contractTask = tasks?.filter(function(task) {
            if(task.task.subtype === "avv" && task.task.contracts.name === contract.name) {
                return task;
            }
        })

        return(
            <tr className="border-b bg-white">
                <td className="whitespace-nowrap px-2 py-4">{contract.name}</td>
                <td className="whitespace-nowrap px-2 py-4">{contract.uploaded_at ? ('0' + updatedAt.getDate()).slice(-2) + '.' + (('0' + (updatedAt.getMonth() + 1)).slice(-2)) + '.' + updatedAt.getFullYear() + ', ' + ('0' + updatedAt.getHours()).slice(-2) + ':' + ('0' + updatedAt.getMinutes()).slice(-2) + ' Uhr' : "-"}</td>
                <td className="whitespace-nowrap px-2 py-4">{contract.serviceprovider.name}</td>
                <td className="whitespace-nowrap px-2 py-4">
                    { contract.uploaded_at !== null ?
                        <button type="button" onClick={() => {downloadContract(contract)}} className="items-center justify-center w-full h-full hover:btn-secondary btn-secondary-active-and-hover">Download</button>
                    :
                        <></>
                    }
                </td>
                <td className="whitespace-nowrap px-2 py-4">
                    <Link type="button" to={`/tasks/${contractTask[0] ? contractTask[0].id : undefined}`} state={{current_task: contractTask[0]}} className="items-center justify-center w-full h-full hover:btn-secondary btn-secondary-active-and-hover text-center">{contract.uploaded_at ? "Erneut hochladen" : "Hochladen"}</Link>
                </td>
            </tr>
        )
    })
    return(
        <div className="m-0 p-3 md:m-5 flex flex-col risk-card  md:w-full max-w-[963px] h-fit bg-white rounded border light-blue-border md:p-[24px] md:flex-row xl:justify-between mb-[48px] w-full">
            <div className="w-[100%] lg:flex-row">
                <div className="flow-root items-center mb-4">
                    <h1 className="float-left text-xl text-ere-content-blue">Dokumente & Verträge</h1>
                </div>
                {listContracts.length !== 0 ?
                    <div className="text-sm mb-4">
                        <table className="min-w-full text-left text-sm font-light">
                            <thead className="border-b bg-white font-medium">
                            <tr className="border-b">
                                <th className="px-2 py-4">Name</th>
                                <th className="px-2 py-4">Zuletzt hochgeladen</th>
                                <th className="px-2 py-4">Service Provider</th>
                                <th className="px-2 py-4"></th>
                                <th className="px-2 py-4"></th>
                            </tr>
                            </thead>
                            <tbody>
                                { listContracts }
                            </tbody>
                        </table>
                    </div>
                :
                    <>Derzeit existieren keine Dokumente oder Verträge</>
                }
            </div>
        </div>
    );



}