import React from "react";
import man from "../../assets/images/lex.jpg";
import certificate from "../../assets/images/tuv-zertifikat.png";

export default function Appointment() {
    return (
        <div className="w-[250px] h-[421px] relative bg-white shadow-sm rounded flex flex-col overflow-hidden p-[24px] text-center items-center">
            <div className="text-2xl font-bold text-ere-content-blue leading-[24px]">Sie haben Fragen?</div>
            <div className="text-sm font-normal text-ere-grey-alternative leading-normal mt-1">Ihr persönlicher <br/>Datenschutzbeauftragter</div>
            <div className="h-20 flex-col self-center align-top">
                <div className="text-xs mt-[20px]">
                    <span className="font-semibold mt-6">Lev Lexow<br /></span>
                    <span className="font-normal break-keep">TÜV-zertifizierter DSB</span>
                    <img className="w-[100px] h-[37px] shadow-xs inline mt-1" src={process.env.REACT_APP_URL + certificate} alt="TÜV-zertifizierter DSB"/>
                </div>

            </div>
            <img className="w-[167px] top-[204px] absolute" src={process.env.REACT_APP_URL + man} alt="Lev Lexow - TÜV-zertifizierter DSB"></img>
            <button className="w-[202px] h-[45px] btn btn-secondary hover:btn-secondary-active-and-hover hover:bg-white z-2 relative text-sm top-[156px] uppercase tracking-[0.233px] font-black">
                termin vereinbaren
            </button>
        </div>
    )

}