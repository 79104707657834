import React from "react";
import Card from "../components/Content/Risk/Card";
import Overview from "../components/Content/Task/Overview";
import ProjectOverview from "../components/Content/Project/Overview";

export default function Dashboard() {
    return <>
        <Card/>
        <Overview isTaskOverview={false}/>
        <ProjectOverview/>
    </>
}