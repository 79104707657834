import React, {useEffect, useState} from "react";
import Popup from "./Hints/Popup";

export default function YesNo({questionAnswers, whenSelected, prefilled}) {
    const [answers, setAnswers] = useState([]);

    useEffect(()=> {
        setAnswers(questionAnswers);
        document.ert_shadow.querySelectorAll('[name="yesno"]').forEach((radio) => {
            if(prefilled && prefilled == radio.value) {
                radio.checked = true;
            }
        })
    },[questionAnswers, prefilled])



    const listAnswers = answers?.map((answer) => (
        <label key={answer.id} className={`relative cursor-pointer has-tooltip transition-all active:scale-95 peer-checked:ring-ere-checkbox-blue flex justify-center overflow-visible min-w-[180px] w-full max-w-[195px] max-h-[107px] h-auto p-3 shadow-md rounded-sm border border-sky-600 
        border-opacity-40 ${answer.tooltip ? "z-30" : "z-10"}`}>
            <input type="radio" className="peer sr-only" name="yesno" value={answer.id} onChange={(e) => whenSelected(answer, e)}/>
            <span className="absolute opacity-0 transition-all peer-checked:opacity-100 right-[-10px] top-[-10px]">
                <div className="bg-ere-checkbox-blue rounded-full w-[24px]  text-center ">
                    <span className="icon-check"/>
                </div>
            </span>
            { answer.tooltip ?
                <Popup popupAnswer={answer.tooltip}/>
            :
                <></>
            }
            <div>
                <div className="text-[40px]">
                    {answer.title === "yes" ? <span className="icon-ja"></span> : <span className="icon-nein"></span>}
                </div>
                <header className="flex flex-col justify-center items-center">
                    <p className="text-base">{answer.title === "yes" ? "Ja" : "Nein"}</p>
                </header>
            </div>
        </label>
    ))

    return(
        <div className="grid grid-cols-1 gap-5 s:grid-cols-2 md:gap-3 mb-6 w-max md:w-fit self-center">
            {listAnswers}
        </div>
    );
}