import React, {useEffect, useState} from "react";
import {fetchCategories} from "../../utils/api/categories";
import {bearerToken, checkForChildren} from "../../utils/helpers";
import Box from "../../components/Content/Category/Box";
import RiskCard from "../../components/Content/Risk/Card";

export default function Category() {
    const [categories, setCategories] = useState([]);

    useEffect(()=> {
        function waitForToken() {
            if(typeof bearerToken !== "undefined") {
                fetchCategories(setCategories);
            } else {
                setTimeout(waitForToken, 250);
            }
        }
        waitForToken()
    },[])

    // TODO: Sobald API funktioniert, umschreiben (Array.from(Object.values(categories)))
    const listCategories = Array.from(Object.values(categories))?.map(category => (
        <Box key={category.id} category={category} hasChildren={checkForChildren(category)}/>
    ))

    return(
        <>
            <RiskCard riskValue={Math.floor(Math.random()*100)} riskReason="Marketing-Angaben"/>
            <h1 className="xl:text-2xl font-bold mb-4 text-ere-content-blue outlet-title md:mb-4 lg:self-start self-center">Basisdatenschutz</h1>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[18px] lg:grid-cols-3 md:w-max category-wrapper">
                {listCategories}
                {/*<EmployeeDataProtection></EmployeeDataProtection>*/}
            </div>
        </>
    );
}