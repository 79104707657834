import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {fetchCategory} from "../../utils/api/categories";
import {bearerToken, checkForChildren} from "../../utils/helpers";
import Box from "../../components/Content/Category/Box";
import RiskCard from "../../components/Content/Risk/Card";
import OtherActivities from "../../components/Content/Category/OtherActivities";


export default function ShowChildren() {
    const [category, setCategory] = useState([]);

    let {category_id} = useParams();
    let children = Array.isArray(category) ? Object.assign({}, ...category).children : undefined;
    useEffect(() => {
        function waitForToken() {
            if(typeof bearerToken !== "undefined") {
                fetchCategory(setCategory, category_id)
            } else {
                setTimeout(waitForToken, 250);
            }
        }
        waitForToken()
    },[category_id])

    const listCategoryChildren = children?.map(child => (
        <Box key={child.id} category={child} hasChildren={checkForChildren(child)}/>
    ))

    return(
        <>
            <RiskCard riskValue={Math.floor(Math.random()*100)} riskReason="Marketing-Angaben"/>
            <h1 className="xl:text-2xl font-bold mb-4 text-ere-content-blue outlet-title md:mb-4 lg:self-start self-center">Verarbeitungsverzeichnis</h1>
            <div className="w-full category-wrapper grid grid-cols-1 sm:grid-cols-2 md:w-max gap-[18px] lg:grid-cols-3">
                {listCategoryChildren}
                <OtherActivities link="/"></OtherActivities>
            </div>
        </>
    );
}